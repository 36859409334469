import React, { useState } from 'react';
import styles from './styles.module.css';
import Notification from '../notification/Notification';
import routes from '../../utils/routes';

const ForgotPasswordPopup = ({ show, handleClose }) => {
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!email) {
                setErrorMessage('Please enter your email.');
                return;
            }
            console.log('Verification email sent to:', email);

            const res = await routes.APIS.resetPassword({ email });
            if (res.status === 200) {
                Notification({ message: res.message, type: 'success' });
                setEmail('');
                setErrorMessage('');
                handleClose();
            } else {
                Notification({ message: res.error, type: 'error' });
            }

        } catch (error) {
            console.log(error)
            Notification({ message: "Something went wrong", type: 'error' });
        }
    };

    if (!show) return null;

    return (
        <div className={styles.popupOverlay}>
            <div className={styles.popupContainer}>
                <div className={styles.popupHeader}>
                    <h1>Forgot Password</h1>
                    <button
                        className={styles.closeButton}
                        onClick={handleClose}
                        aria-label="Close"
                    >
                        &times;
                    </button>
                </div>
                <form className={styles.popupForm} onSubmit={handleSubmit}>
                    <label className={styles.formLabel} htmlFor="email">
                        Enter your email address
                    </label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={handleEmailChange}
                        className={styles.formInput}
                        required
                    />
                    {errorMessage && (
                        <p className={styles.errorMessage}>{errorMessage}</p>
                    )}
                    <button type="submit" className={styles.submitButton}>
                        Submit
                    </button>
                    <p className={styles.infoText}>
                        After submission, you will receive a reset password email on your registered email address.
                    </p>
                </form>
            </div>
        </div>
    );
};

export default ForgotPasswordPopup;
