// import logo from './logo.svg';
import './App.css';
import { Route, Routes, useLocation } from "react-router-dom";
import LoginPage from './pages/LoginPage';
import Home from './pages/Home';
import PlantTree from './pages/PlantTree';
import ProjectDetails from './pages/ProjectDetails'
import Registration from './component/login/Registration'
import UserMyProfile from './pages/UserMyProfile';
import UserMyActivity from './pages/UserMyActivity';
import ProtectedRoutes from './ProtectedRoute';
import RegistrationPopup from './component/login/RegistrationPopup';
import ScrollToTop from './ScrollToTop';
import ViewCart from './pages/ViewCart';
import MyOrder from './pages/MyOrder';
import MyGifts from './pages/MyGifts';
import Refund from './pages/Refund';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import SuccessPayment from './pages/SuccessPayment';
import PaymentCancelled from './pages/PaymentCancelled';
import PaymentFailed from './pages/PaymentFailed';
import Cookie from './pages/Cookie';
import OauthCallback from './pages/OauthCallback';
import Blogs from './pages/Blogs';
import BlogDetails from './pages/BlogDetails';
import { useEffect } from 'react';
import ResetPassword from './pages/ResetPassword';

function App() {
  const location = useLocation();

  // Scroll to specific section when location changes
  useEffect(() => {
    const section = document.getElementById("mySection");
    if (section) {
      section.scrollIntoView({ behavior: "auto", block: "start" });
    }
  }, [location]); // Dependency on location change

  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = '/assets/js/jquery.meanmenu.min.js';   //(This is external js url)
  //   script.async = true;
  //   document.body.appendChild(script);
  // }, [])

  return (
    <>
      {/* <Home /> */}
      <div className='wrapper'>
        <section id="mySection">
        </section>

        <ScrollToTop />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/plantTree" element={<PlantTree />} />
          <Route path='/ProjectDetails/:id' element={<ProjectDetails />} />
          <Route path='/login' element={<LoginPage />} />
          <Route path='/registration' element={<Registration />} />
          <Route path='/myProfile' element={<ProtectedRoutes><UserMyProfile /></ProtectedRoutes>} />
          <Route path='/myActivity' element={<ProtectedRoutes><UserMyActivity /></ProtectedRoutes>} />
          <Route path='/register' element={<RegistrationPopup />} />
          <Route path='/view-cart/:id' element={<ProtectedRoutes><ViewCart /></ProtectedRoutes>} />
          <Route path='/my-order' element={<ProtectedRoutes><MyOrder /></ProtectedRoutes>} />
          <Route path='/my-gift' element={<ProtectedRoutes><MyGifts /> </ProtectedRoutes>} />
          <Route path='/refund' element={<Refund />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/privacy-policy' element={<Privacy />} />
          <Route path='/cookie-policy' element={<Cookie />} />
          <Route path='/blogs' element={<Blogs />} />
          <Route path='/blogdetails/:link' element={<BlogDetails />} />

          <Route path='/oauth-callback' element={<OauthCallback />} />


          {/* payment  */}
          <Route path='/payment-success' element={<SuccessPayment />} />
          <Route path='/payment-cancelled' element={<PaymentCancelled />} />
          <Route path='/payment-failed' element={<PaymentFailed />} />


          {/* reset password */}
          <Route path='/reset-password/:userId/:token' element={<ResetPassword />} />

        </Routes>
      </div>
    </>
  );
}

export default App;
