// import { data } from "jquery";
import { apiGet, apiPost, apiPut } from "./Api";
import Localstorage from "./storage/Localstorage";
// const baseURL = process.env.REACT_APP_BACKEND_URL;
// const baseURL = 'http://localhost:4000';
const baseURL = process.env.REACT_APP_BACKEND_URL;
// const baseURL = "https://treetology-backend.onrender.com";


export const routes = {
    BASE_PATH: `${baseURL}`,
    APIS: {
        userlogin: async (data) => {
            // console.log("datadata",data)
            // console.log('BASE_PATH',routes.BASE_PATH)
            return await apiPost("/enduser/log-in", routes.BASE_PATH, data)
        },
        registeredUser: async (data) => {
            // console.log('BASE_PATH',routes.BASE_PATH)
            return await apiPost('/enduser/sign-up', routes.BASE_PATH, data)
        },
        getUserInfo: async () => {
            const token = Localstorage.JWT_TOKEN.get();
            return await apiGet("/enduser/getUserInfo", routes.BASE_PATH, token)
        },
        updatePasswordByID: async (id, data) => {
            return await apiPost(`/enduser/change-password`, routes.BASE_PATH, data)
        },
        UpdateProfileById: async (id, data) => {
            return await apiPut(`/enduser/update`, routes.BASE_PATH, data)
        },
        // createGift: async(data) =>{
        //     return await apiPost(`/createGiftTree`,routes.BASE_PATH,data)
        // },


        getAllProjects: async () => {
            return await apiGet(`/project/getAllWebProjects`, routes.BASE_PATH)
        },
        getProjectById: async (id) => {
            return await apiGet(`/project/getProject/${id}`, routes.BASE_PATH)
        },
        getProjectByLink: async (link) => {
            return await apiGet(`/project/getProjectByLink/${link}`, routes.BASE_PATH)
        },


        getAllBlogs: async () => {
            return await apiGet(`/blog/all`, routes.BASE_PATH);
        },
        getBlogById: async (id) => {
            return await apiGet(`/blog/getBlog/${id}`,routes.BASE_PATH);
        },
        getBlogByLink: async (link) => {
            return await apiGet(`/blog/bloglink/${link}`, routes.BASE_PATH);
        },



        createCart: async (data) => {
            return await apiPost('/cart/create', routes.BASE_PATH, data)
        },
        getcartProduct: async () => {
            return await apiGet('/cart/get', routes.BASE_PATH)
        },
        addingToCart: async (data) => {
            return await apiPost('/cart/to-add', routes.BASE_PATH, data)
        },
        removeCart: async () => {
            return await apiPost('/cart/remove', routes.BASE_PATH)
        },
        createGift: async (data) => {
            return await apiPost('/createGift', routes.BASE_PATH, data)
        },
        getOrdersWithoutGiftByuserId: async (userId) => {
            return await apiGet(`/order/withoutGiftOrderByUserId/${userId}`, routes.BASE_PATH,)
        },
        getOrdersWithGiftByuserId: async (userId) => {
            return await apiGet(`/order/giftOrderByUserId/${userId}`, routes.BASE_PATH)
        },
        getorderUploadedImagesbySubadminbyId: async (orderId) => {
            return await apiGet(`/order-task/getorderUploadedImagesbySubadminbyId/${orderId}`, routes.BASE_PATH)
        },
        resetPassword: async (data) => {
            return await apiPost("/enduser/reset-password", routes.BASE_PATH, data)
        },
        resetPasswordUsingLink: async (data) => {
            return await apiPost("/enduser/reset-password-using-link", routes.BASE_PATH, data)
        },
    }
}

export default routes;