import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Button, Alert, Container, Row, Col } from 'react-bootstrap';
import styles from './styles.module.css';
import routes from '../../utils/routes';
import Notification from '../notification/Notification';

const ResetPasswordPage = () => {
    const { userId, token } = useParams(); // Extract userId and token from the URL
    const navigate = useNavigate(); // Hook for navigation
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        if (!userId || !token) {
            setErrorMessage('Invalid or expired reset link.');
        }
    }, [userId, token]);

    const handleNewPasswordChange = (e) => {
        setNewPassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!newPassword || !confirmPassword) {
            setErrorMessage('Both fields are required.');
            setSuccessMessage('');
            return;
        }
        if(newPassword.length < 8){
            setErrorMessage('Password length Minimum 8');
            setSuccessMessage('');
            return;
        }

        if (newPassword !== confirmPassword) {
            setErrorMessage('Passwords do not match.');
            setSuccessMessage('');
            return;
        }

        try {
            const res = await routes.APIS.resetPasswordUsingLink({
                password: newPassword,
                userId,
                token,
            });

            if (res.status === 200) {
                Notification({ message: res.message, type: 'success' });
                setSuccessMessage('Your password has been reset successfully.');
                setErrorMessage('');
                setNewPassword('');
                setConfirmPassword('');
                navigate('/login'); // Navigate to login page after successful reset
            } else {
                setErrorMessage(res.error || 'Failed to reset password. Please try again.');
                setSuccessMessage('');
                Notification({ message: res.error, type: 'success' });

            }
        } catch (error) {
            console.error('Error resetting password:', error);
            setErrorMessage('Link is expired or invalid.');
            setSuccessMessage('');
        }
    };

    return (
        <div className="reset-password-container">
            <Container>
                <Row className="justify-content-center">
                    <Col md={6} lg={5}>
                        <div className={`${styles.resetPasswordPage} form-wrapper p-4`}>
                            <h2 className={`${styles.headingText} text-center mb-4`}>Reset Password</h2>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formNewPassword" className="mb-3">
                                    <Form.Label className={`${styles.customLabel}`}>New Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Enter new password"
                                        value={newPassword}
                                        onChange={handleNewPasswordChange}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group controlId="formConfirmPassword" className="mb-3">
                                    <Form.Label className={`${styles.customLabel}`}>Confirm Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Confirm new password"
                                        value={confirmPassword}
                                        onChange={handleConfirmPasswordChange}
                                        required
                                    />
                                </Form.Group>

                                {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                                {successMessage && <Alert variant="success">{successMessage}</Alert>}

                                <Button type="submit" className={`${styles.customButton} w-100 mt-3`}>
                                    Reset Password
                                </Button>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ResetPasswordPage;
