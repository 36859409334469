// import logoImage from "/assets/certificate/3-2.png";
// import logoImage from "../certificate/3-2.png";
import logoImage from "../certificateImg/3-2.png";
// import backgroundImage from "/assets/certificate/background.png";
// import backgroundImage from "../certificate/background.png";
import backgroundImage from "../certificateImg/background.png";
// import bookImage from "/assets/certificate/book-icon.png";
// import bookImage from "../certificate/book-icon.png";
// import bookImage from "../certificateImg/book-icon.png";
// import calendarImage from "/assets/certificate/calendar-icon.png";
// import calendarImage from "../certificate/calendar-icon.png";
import calendarImage from "../certificateImg/calendar-icon.png";
// import locationImage from "/assets/certificate/location-icon.png";
// import locationImage from "../certificate/location-icon.png";
import locationImage from "../certificateImg/location-icon.png";
// import sideLeftImage from "/assets/certificate/side-left.png";
// import sideLeftImage from "../certificate/side-left.png";
import sideLeftImage from "../certificateImg/side-left.png";
// import sideRightImage from "/assets/certificate/side-right.png";
// import sideRightImage from "../certificate/side-right.png";
import sideRightImage from "../certificateImg/side-right.png";
// import treeImage from "/assets/certificate/tree-icon.png";
// import treeImage from "../certificate/tree-icon.png";
import treeImage from "../certificateImg/tree-icon.png";
// import signatureImage from "/assets/images/signature.png";
// import signatureImage from "../certificate/signature.png";
import signatureImage from "../certificateImg/signature.png";

import moment from "moment";
// import { useSession } from "next-auth/react";
// import { Archivo } from "next/font/google";
import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { CertificateContainer } from "./Certificate-style";

// const archivoFont = Archivo({
//   subsets: ["latin"],
//   weight: ["100", "200", "400", "700", "900"],
// });

function Certificate({ user, data }, ref) {

  console.log("Certificate", "user:", user, "data:", data);
  const [qrCodeValue, setQrCodeValue] = useState(null);

  // const { data: session } = useSession();

  // useEffect(() => {
  //   if (typeof window !== "undefined") {
  //     setQrCodeValue(
  //       `${new URL(window?.location).origin}/user-view-profile/${
  //         session?.session?.user?.link
  //       }`
  //     );
  //   }
  // }, [session?.session?.user?.link]);
  useEffect(() => {
    if (typeof window !== "undefined") {
      setQrCodeValue(`${new URL(window?.location).origin}/user-view-profile/${user?.link}`);
    }
  }, [user?.link]);

  return (
    <>
      <CertificateContainer>
        {/* ${archivoFont.className} */}
        <div ref={ref} className={`main-container`} style={{ fontFamily: 'Archivo, sans-serif' }}>
          <img
            className="background-img"
            src={backgroundImage}
            alt="backgroundImage"
          />
          <img
            className="right-side-image"
            src={sideRightImage}
            alt="sideRightImage"
          />
          <img
            className="left-side-image"
            src={sideLeftImage}
            alt="sideLeftImage"
          />

          <div className="content-footer-container">
            <div className="content-container">
              <div className="logo-qr-container">
                <img
                  className="logo-image"
                  src={logoImage}
                  alt="logoImage"
                  height={200}
                  width={446}
                />
                {qrCodeValue ? (
                  <QRCode
                    size={133}
                    value={qrCodeValue}
                    className="qr-code-image"
                    bgColor="#2c6137"
                    fgColor="#ffffff"
                  />
                ) : null}
              </div>
              <div className="cert-title">CERTIFICATE OF OWNERSHIP</div>

              <div className="certificate-description above">
                <p className="text">
                  This certificate acknowledges that{" "}
                  <span className={`fw-800`}>{user?.name}</span> has made a
                  valuable contribution to
                  <br />
                </p>

                <p className="text">
                  {/* our planet by planting the following tree(s): */}
                  our planet by supporting the following project:
                </p>
              </div>

              <div className="purchase-detail-container">
                {/* <div className="card-container tree-name-container">
                  <img
                    src={treeImage}
                    alt="treeImage"
                    width={35}
                    height={35}
                  />
                  <div>{data?.projectName}</div>
                </div> */}
                <div className="card-container tree-name-container" style={{ display: 'flex', alignItems: 'center', overflow: 'hidden', padding: '8px', boxSizing: 'border-box' }}>
                  <img
                    src={treeImage}
                    alt="treeImage"
                    width={35}
                    height={35}
                    style={{ marginRight: '8px' }}
                  />
                  <div style={{ maxWidth: '250px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    {data?.projectName}
                  </div>
                </div>


                {/* <div className="card-container species-name-container">
                  <img
                    src={bookImage}
                    alt="bookImage"
                    width={35}
                    height={35}
                  />
                  <div>{data?.scientificSpecies || "N/A"}</div>
                </div> */}

                <div className="card-container region-name-container">
                  <img
                    src={locationImage}
                    alt="locationImage"
                    width={35}
                    height={35}
                  />
                  <div>{data?.location}</div>
                </div>

                <div className="card-container date-name-container">
                  <img
                    src={calendarImage}
                    alt="calendarImage"
                    width={35}
                    height={35}
                  />
                  {/* <div>{moment(data?.purchasedDate).format("DD MMM YYYY")}</div> */}
                  <div>Date: {moment(data?.purchasedDate).format("DD MMM YYYY")}</div>
                </div>
              </div>

              <div className="certificate-description below">
                <p className="text">
                  Thank you <span className="fw-800">{user?.name}</span>, for
                  choosing Treetology and making a lasting impact. Your
                  contribution not only
                </p>

                <p className="text">
                  adds to the beauty of our world but also plays a crucial role in reforesting
                  our environment. Every tree planted is a step
                </p>

                <p className="text">closer to a greener, more sustainable future.</p>
              </div>

              <div className="signature-container">
                <img
                  src={signatureImage}
                  alt="signatureImage"
                  height={140}
                  width="auto"
                />
                <div className="text-wrapper">Authorized Signature</div>
              </div>
            </div>

            <div className="footer">
              <p className="detail">
                Treetology - Planting for a Greener Tomorrow
              </p>
              <p className="link">www.treetology.com</p>
            </div>
          </div>
        </div>
      </CertificateContainer>
    </>
  );
}

export default React.forwardRef(Certificate);
