import logoImage from "../certificateImg/3-2.png";
import backgroundImage from "../certificateImg/background.png";
import bookImage from "../certificateImg/book-icon.png";
import calendarImage from "../certificateImg/calendar-icon.png";
import locationImage from "../certificateImg/location-icon.png";
import sideLeftImage from "../certificateImg/side-left.png";
import sideRightImage from "../certificateImg/side-right.png";
import treeImage from "../certificateImg/tree-icon.png";
import signatureImage from "../certificateImg/signature.png";

import moment from "moment";
import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { CertificateContainer } from "./Certificate-style";

function GiftCertificate({ user, data }, ref) {

    console.log("Certificate", "user:", user, "data:", data);
    const [qrCodeValue, setQrCodeValue] = useState(null);

    useEffect(() => {
        if (typeof window !== "undefined") {
            setQrCodeValue(`${new URL(window?.location).origin}/user-view-profile/${user?.link}`);
        }
    }, [user?.link]);

    return (
        <>
            <CertificateContainer>
                <div ref={ref} className={`main-container`} style={{ fontFamily: 'Archivo, sans-serif' }}>
                    <img
                        className="background-img"
                        src={backgroundImage}
                        alt="backgroundImage"
                    />
                    <img
                        className="right-side-image"
                        src={sideRightImage}
                        alt="sideRightImage"
                    />
                    <img
                        className="left-side-image"
                        src={sideLeftImage}
                        alt="sideLeftImage"
                    />

                    <div className="content-footer-container">
                        <div className="content-container">
                            <div className="logo-qr-container">
                                <img
                                    className="logo-image"
                                    src={logoImage}
                                    alt="logoImage"
                                    height={150}
                                    width={350}
                                />
                                {qrCodeValue ? (
                                    <QRCode
                                        size={133}
                                        value={qrCodeValue}
                                        className="qr-code-image"
                                        bgColor="#2c6137"
                                        fgColor="#ffffff"
                                    />
                                ) : null}
                            </div>
                            <div className="cert-title-gift">GIFT CERTIFICATE</div>

                            <div className="certificate-description above mb-4">
                                <p className="text">
                                    This certificate is proudly presented to{" "}
                                    <span className={`fw-800`}>{data?.receiverName}</span> as a gift from <span className={`fw-800`}>{data?.giftBy}</span> celebrating
                                    <br />
                                </p>

                                <p className="text">
                                    their shared commitment to a healthier planet. By planting these trees, they contribute  to a greener future
                                    <br />
                                </p>

                                <p className="text">
                                    for generations.They have made a valuable contribution to our planet by supporting the following project:
                                    <br />

                                </p>

                            </div>

                            <div className="purchase-detail-container">
                                {/* <div className="card-container tree-name-container">
                                    <img
                                        src={treeImage}
                                        alt="treeImage"
                                        width={35}
                                        height={35}
                                    />
                                    <div>{data?.projectName}</div>
                                </div> */}
                                <div className="card-container tree-name-container" style={{ display: 'flex', alignItems: 'center', overflow: 'hidden', padding: '8px', boxSizing: 'border-box' }}>
                                    <img
                                        src={treeImage}
                                        alt="treeImage"
                                        width={35}
                                        height={35}
                                        style={{ marginRight: '8px' }}
                                    />
                                    <div style={{ maxWidth: '250px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                        {data?.projectName}
                                    </div>
                                </div>

                                <div className="card-container species-name-container">
                                    <img
                                        src={bookImage}
                                        alt="bookImage"
                                        width={35}
                                        height={35}
                                    />
                                    <div>{data?.occasion || "N/A"}</div>
                                </div>

                                <div className="card-container region-name-container">
                                    <img
                                        src={locationImage}
                                        alt="locationImage"
                                        width={35}
                                        height={35}
                                    />
                                    <div>{data?.location}</div>
                                </div>

                                <div className="card-container date-name-container">
                                    <img
                                        src={calendarImage}
                                        alt="calendarImage"
                                        width={35}
                                        height={35}
                                    />
                                    {/* <div>{moment(data?.purchasedDate).format("DD MMM YYYY")}</div> */}
                                    <div>{moment(data?.purchasedDate).format("DD MMM YYYY")}</div>
                                </div>
                            </div>

                            {/* <div className="certificate-description below">
                                <p className="text">
                                    Thank you,
                                    for
                                    choosing Treetology to make a lasting impact.
                                </p>

                                <p className="text">
                                    Your contribution helps reforest our environment, promoting a greener, more sustainable future.
                                </p>
                            </div> */}
                            <div className="certificate-description below">
                                <p className="text">
                                    Thank you <span className="fw-800">{user?.name}</span>, for
                                    choosing Treetology and making a lasting impact. Your
                                    contribution not only
                                </p>

                                <p className="text">
                                    adds to the beauty of our world but also plays a crucial role in reforesting
                                    our environment. Every tree planted is a step
                                </p>

                                <p className="text">closer to a greener, more sustainable future.</p>
                            </div>

                            <div className="signature-container">
                                <img
                                    src={signatureImage}
                                    alt="signatureImage"
                                    height={140}
                                    width="auto"
                                />
                                <div className="text-wrapper">Authorized Signature</div>
                            </div>
                        </div>

                        <div className="footer">
                            <p className="detail">
                                Treetology - Planting for a Greener Tomorrow
                            </p>
                            <p className="link">www.treetology.com</p>
                        </div>
                    </div>
                </div>
            </CertificateContainer>
        </>
    );
}

export default React.forwardRef(GiftCertificate);
