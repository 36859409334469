import React from 'react'
import Header from '../component/Header';
import Footer from '../component/Footer'
import ResetPasswordPage from '../component/ResetPage/ResetPasswordPage';


const ResetPassword = () => {
    return (
        <>
            <Header />
            <ResetPasswordPage />
            <Footer />

        </>
    )
}

export default ResetPassword

