import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import { useNavigate, useParams } from 'react-router-dom'
import SpaIcon from '@mui/icons-material/Spa';
import { Carousel, Modal } from 'antd';
import { fetchEndUserInfo } from '../../store/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import useRazorpay from 'react-razorpay';
import { fetchwebsiteProjectsByLink } from '../../store/projectSlice';
import Localstorage from '../../utils/storage/Localstorage';
import TextArea from 'antd/es/input/TextArea';
import { loadStripe } from '@stripe/stripe-js';
import useCurrencyConverter from '../../store/useCurrencyConverter';
import './styles.module.css';
// import { PayPalButtons } from "@paypal/react-paypal-js";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function ViewCartPage() {
    const link = useParams();
    const [selectedBtnType, setSelectedBtnType] = useState('')
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [Razorpay] = useRazorpay();
    const [paymentGateway, setPaymentGateway] = useState(null);

    const selectedProject = useSelector((state) => state.projectReducer.selectedProject)
    const selectedCurrency = useSelector((state) => state.currencyReducer.selectedCurrency);
    const { exchangeRate } = useCurrencyConverter(selectedCurrency);

    const [formData, setFormData] = useState({
        recieverName: '',
        fromEmail: '',
        title: '',
        MobileNumber: "",
        message: '',
        delieveryDate: ''
    });
    const [errors, setErrors] = useState({});

    const validate = () => {
        let formErrors = {};
        if (!formData.recieverName) formErrors.recieverName = 'Reciever Name is required';
        if (!formData.fromEmail) formErrors.fromEmail = 'From Email is required';
        if (!formData.title) formErrors.title = 'Occasion is required';
        if (!formData.message) formErrors.message = 'Message is required';
        if (!formData.delieveryDate) formErrors.delieveryDate = 'Delivery Date is required';  // Validate deliveryDate
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const resetFormData = () => {
        setFormData({
            recieverName: '',
            fromEmail: '',
            title: '',
            MobileNumber: '',
            message: '',
            delieveryDate: '',
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate form data
        if (!validate(formData)) {
            return;
        }

        // Close the modal for better UX while the payment is processed
        setIsModalVisible(false);

        try {
            // Proceed with payment
            const paymentSuccess = await handlePayment();

            if (paymentSuccess) {
                // Reset form data only if payment is successful
                resetFormData();
            } else {
                console.error("Payment failed or was not completed.");
                // Optionally show error to the user or re-open the modal
            }
        } catch (error) {
            console.error("Unexpected error during payment process:", error);
            // Optionally handle unexpected errors
        }
    };

    const userData = useSelector((state) => state.userReducer.userInfo)
    const chkOutdata = useSelector((state) => state.projectReducer.checkoutData)

    useEffect(() => {
        dispatch(fetchEndUserInfo())
    }, [dispatch])


    useEffect(() => {
        dispatch(fetchwebsiteProjectsByLink(link.id))
    }, [dispatch, link.id])

    useEffect(() => {
        if (chkOutdata && exchangeRate) {
            const { qty, plantPrice, adoptPrice, selectedOption } = chkOutdata;
            let price = 0;
            if (selectedOption === 'plant') {
                // price = plantPrice;
                price = plantPrice * exchangeRate; // Convert plantPrice to selected currency
            } else if (selectedOption === 'adopt') {
                // price = adoptPrice;
                price = adoptPrice * exchangeRate; // Convert adoptPrice to selected currency
            }
            // setTotalPrice(price * qty);
            setTotalPrice((price * qty).toFixed(2));
        }
    }, [chkOutdata, exchangeRate]);
    const handleButtonClick = (option) => {
        setErrors((prevErrors) => ({
            ...prevErrors,
            selectedBtnType: '', // Clear the error for required validation
        }));
        setSelectedBtnType(option);
        if (option === 'giftYes') {
            setIsModalVisible(true);
        }
    };
    const carouselSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        if (!userData && !userData?._id && Object.keys(userData).length === 0) {
            navigate('/login');
        }
    }, [userData, navigate]);

    const validatePayment = () => {
        let errorsPayment = {};
        if (!selectedBtnType) errorsPayment.selectedBtnType = 'Please select the any option';
        // paymentGateway
        if (!paymentGateway) errorsPayment.paymentGateway = 'Please select the payment gateway option';
        setErrors(errorsPayment);
        return Object.keys(errorsPayment).length === 0;
    };

    const handlePayment = async () => {
        // e.preventDefault();
        if (!validatePayment(selectedBtnType, paymentGateway)) {
            // return;
            return false;
        }

        const amount = totalPrice;
        const isGift = selectedBtnType === "giftYes";
        let giftDetails = {};

        if (isGift) {
            giftDetails = {
                message: formData.message,
                receiverName: formData.recieverName,
                receiverEmail: formData.fromEmail,
                occasion: formData.title,
                mobileNumber: formData.MobileNumber,
                deliveryDate: formData.delieveryDate,
            };
        }

        try {
            const token = Localstorage.JWT_TOKEN.get(); // Get the token

            if (paymentGateway === "razorpay") {
                try {
                    // Step 1: Create Razorpay order
                    const { data } = await axios.post(
                        `${process.env.REACT_APP_BACKEND_URL}/order/create-razorpay-order`,
                        { amount, currency: selectedCurrency },
                        {
                            headers: {
                                authorization: `Bearer ${token}`,
                            },
                        }
                    );

                    const options = {
                        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
                        amount: data.amount,
                        currency: { selectedCurrency },
                        name: selectedProject?.name,
                        order_id: data.id,
                        contact: userData.mobileNumber,
                        handler: async function (response) {
                            console.log("handler****", response);
                            // Step 2: Verify Razorpay order
                            try {
                                const verifyResponse = await axios.post(
                                    `${process.env.REACT_APP_BACKEND_URL}/order/verify-razorpay-order`,
                                    {
                                        razorpayOrderId: response.razorpay_order_id,
                                        razorpayPaymentId: response.razorpay_payment_id,
                                        razorpaySignature: response.razorpay_signature,
                                    },
                                    {
                                        headers: {
                                            authorization: `Bearer ${token}`,
                                        },
                                    }
                                );

                                if (verifyResponse.data.msg === 'success') {
                                    const orderResponse = await axios.post(
                                        `${process.env.REACT_APP_BACKEND_URL}/order/create`,
                                        {
                                            userId: userData._id,
                                            plants: [
                                                {
                                                    projectId: selectedProject?._id,
                                                    quantity: chkOutdata.qty,
                                                    selectType: chkOutdata.selectedOption,
                                                    giftId: isGift ? giftDetails : null,
                                                },
                                            ],
                                            total: amount,
                                            address: userData.address,
                                            phoneNumber: userData.mobileNumber,
                                            paymentMethod: 'Razorpay',
                                            payment_info: {
                                                orderId: response.razorpay_order_id,
                                                paymentId: response.razorpay_payment_id,
                                            },
                                        },
                                        {
                                            headers: {
                                                authorization: `Bearer ${token}`,
                                            },
                                        }
                                    );

                                    if (orderResponse.status === 201) {
                                        console.log('Order created successfully!');
                                        // navigate('/my-order');
                                        navigate(isGift ? '/my-gift' : '/my-order');
                                        return true;
                                    } else {
                                        console.error('Failed to create order');
                                        return false;
                                    }
                                } else {
                                    console.error('Payment verification failed');
                                    resetFormData();
                                }
                            } catch (verifyError) {
                                console.error('Error verifying Razorpay order:', verifyError);
                            }
                        },
                        prefill: {
                            name: userData.name,
                            email: userData.email,
                            contact: userData.mobileNumber,
                        },
                        theme: {
                            color: '#3399cc',
                        },
                    };

                    const paymentObject = new Razorpay(options);
                    paymentObject.open();
                } catch (error) {
                    console.error('Error creating Razorpay order:', error);
                }
            } else if (paymentGateway === "stripe") {
                try {
                    const stripe = await stripePromise; // Assuming you have already initialized Stripe with stripePromise

                    // Step 1: Create Stripe Payment Intent
                    const { data } = await axios.post(
                        `${process.env.REACT_APP_BACKEND_URL}/order/create-stripe-order`,
                        {
                            amount,
                            currency: selectedCurrency,
                            name: selectedProject?.name,
                            userId: userData._id, // Pass necessary user data
                            plants: [
                                {
                                    projectId: selectedProject?._id,
                                    quantity: chkOutdata.qty,
                                    selectType: chkOutdata.selectedOption,
                                    giftId: isGift ? giftDetails : null,
                                },
                            ], // Add the plants information here
                            total: amount,
                            // address: userData.address,
                            phoneNumber: userData.mobileNumber
                        },
                        {
                            headers: {
                                authorization: `Bearer ${token}`,
                            },
                        }
                    );

                    // Step 2: Redirect to Stripe Checkout using the sessionId
                    const result = await stripe.redirectToCheckout({
                        sessionId: data.sessionId,
                    });
                    console.log("stripeorder********", result)

                    if (result.error) {
                        console.error("Stripe checkout error:", result.error.message);
                        return false;
                    }
                    return true;
                } catch (error) {
                    console.error("Error during Stripe payment:", error);
                }

            } else if (paymentGateway === "paypal") {
                try {
                    // Step 1: Create PayPal order
                    const { data: paypalData } = await axios.post(
                        `${process.env.REACT_APP_BACKEND_URL}/order/create-paypal-order`,
                        { amount },
                        {
                            headers: {
                                authorization: `Bearer ${token}`,
                            },
                        }
                    );

                    const orderID = paypalData.orderID;
                    console.log(orderID)

                    // Step 2: Render PayPal Buttons
                    window.paypal.Buttons({
                        createOrder: (data, actions) => {
                            return actions.order.create({
                                purchase_units: [{
                                    amount: {
                                        value: amount, // Pass the correct amount
                                    },
                                }],
                            });
                        },
                        onApprove: async (data, actions) => {
                            try {
                                const captureData = await actions.order.capture();
                                await axios.post(
                                    `${process.env.REACT_APP_BACKEND_URL}/order/verify-paypal-order`,
                                    { orderId: data.orderID },
                                    {
                                        headers: {
                                            authorization: `Bearer ${token}`,
                                        },
                                    }
                                );
                                console.log("Payment successful!", captureData);
                                // Navigate or update UI after successful payment
                                navigate("/my-order");
                                return true; 
                            } catch (error) {
                                console.error('Error during PayPal order capture:', error);
                                return false;
                            }
                        },
                        onError: (err) => {
                            console.error("PayPal checkout error:", err);
                            return false;
                        },
                    }).render('#paypal-button-container'); // Ensure PayPal button container exists
                } catch (error) {
                    console.error("Error creating PayPal order:", error);
                }
            }
        } catch (error) {
            console.error("Payment error:", error);
        }
    };


    const handlePaymentSelection = (gateway) => {
        setErrors((prevErrors) => ({
            ...prevErrors,
            paymentGateway: '', // Clear the error for required validation
        }));
        console.log("handlePaymentSelection", gateway);
        setPaymentGateway(gateway);
    };


    // Custom Arrow Components
    const CustomPrevArrow = ({ onClick }) => (
        <button className={styles.customArrowLeft} onClick={onClick}>
            <LeftOutlined />
        </button>
    );

    const CustomNextArrow = ({ onClick }) => (
        <button className={styles.customArrowRight} onClick={onClick}>
            <RightOutlined />
        </button>
    );


    return (
        <>
            <div className={`container ptb-120`}>
                <div className='row justify-content-center'>
                    <div className='col-lg-9'>
                        <div className='row checkout-box'>
                            <div className='col-lg-6 col-md-6 mx-auto box2'>
                                <Carousel autoplay d dots={false}
                                    arrows
                                    prevArrow={<CustomPrevArrow />}
                                    nextArrow={<CustomNextArrow />} {...carouselSettings}>
                                    {selectedProject?.images?.map((imgSrc, index) => (
                                        <div key={index} className={styles.mainImage}>
                                            <img
                                                src={process.env.REACT_APP_BACKEND_URL + "/" + imgSrc}
                                                alt={`ProjectImage ${index}`}
                                                className="img-fluid"
                                            />
                                        </div>
                                    ))}
                                </Carousel>
                            </div>
                            <div className='col-lg-6 col-md-6 box1'>
                                <div className={styles.contentSection}>
                                    <h1 style={{ fontWeight: '800' }}>{selectedProject?.name}</h1>
                                    <p>
                                        {selectedProject?.description}
                                    </p>
                                    {/* <div className='row justify-content-center'>
                                        {chkOutdata.selectedOption === 'plant' && <div className={`col-6 `}>
                                            <button
                                                className={`ch-btn-style-2 btn mt-4 w-100 ${chkOutdata.selectedOption === 'plant' ? 'btn-success' : styles.activeTypeBtn}`}
                                                style={{ padding: '1rem' }}
                                            >
                                                Plant Now
                                            </button>
                                        </div>}
                                        {chkOutdata.selectedOption === 'adopt' && <div className='col-6'>
                                            <button
                                                className={`ch-btn-style-2 btn mt-4 w-100 ${chkOutdata.selectedOption === 'adopt' ? 'btn-success' : styles.activeTypeBtn}`}
                                                style={{ padding: '1rem' }}
                                            >
                                                Adopt Tree
                                            </button>
                                        </div>}
                                    </div> */}

                                </div>
                            </div>
                            <div className='row justify-content-center'>
                                <div className='col-lg-12'>
                                    <div className='row '>
                                        <div className={styles.contentSection}>
                                            <div className='row mt-3 justify-content-between'>
                                                <div className="col-lg-6 col-sm-6 justify-content-center align-items-center">
                                                    <h2 className={`${styles.textContentStyle}`} style={{ fontSize: '1.8rem', color: 'white' }}><span><SpaIcon className={styles.icon} /></span><strong>Number of Trees : {chkOutdata.qty}</strong></h2>
                                                </div>
                                                <div className="col-lg-6 col-sm-6 justify-content-center align-items-center">
                                                    <h2 className={`${styles.textContentStyle}`} style={{ fontSize: '1.8rem', color: 'white' }}><span><SpaIcon className={styles.icon} /></span><strong>Total Price : {selectedCurrency} {totalPrice}</strong></h2>
                                                </div>
                                            </div>
                                            <div className='row justify-content-between'>

                                                <div className={`col-lg-6 row`}>
                                                    <div className={`col-lg-6 justify-content-center align-items-center`}>
                                                        <h2 className={`${styles.textContentStyle}`} style={{ fontSize: '1.8rem', color: 'white' }}><span><SpaIcon className={styles.icon} /></span><strong>Is this a gift</strong></h2>
                                                    </div>
                                                    <div className={`col-lg-6 justify-content-center align-items-center `}>
                                                        <div className='row' style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '1rem', }}>

                                                            <div className='col-2 col-lg-4 mt-3'>
                                                                <button
                                                                    className={`btn btn-border ${selectedBtnType === 'giftYes' ? 'btn-success' : styles.activeTypeBtn}`}
                                                                    onClick={() => handleButtonClick('giftYes')}
                                                                >
                                                                    Yes
                                                                </button>
                                                            </div>
                                                            <div className='col-2 col-lg-4 mt-3'>
                                                                <button
                                                                    className={`btn btn-border ${selectedBtnType === 'giftNo' ? 'btn-success' : styles.activeTypeBtn}`}
                                                                    onClick={() => handleButtonClick('giftNo')}
                                                                >
                                                                    No
                                                                </button>
                                                            </div>

                                                            {errors.selectedBtnType && <div className="text-danger">{errors.selectedBtnType}</div>}

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={`col-lg-6`}>

                                                    <div className='row justify-content-between'>
                                                        <div className={`col-lg-5 justify-content-center align-items-center`}>
                                                            <h2 className={`${styles.textContentStyle}`} style={{ fontSize: '1.8rem', color: 'white' }}><span><SpaIcon className={styles.icon} /></span><strong>Pay Using</strong></h2>
                                                        </div>
                                                        <div className={`col-lg-7 justify-content-center align-items-center`}>
                                                            <div className='row' style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '50px', marginLeft: '1rem', }}>

                                                                <div className='col-3 col-lg-4 mt-3'>
                                                                    <label className={`btn btn-border ${paymentGateway === 'paypal' ? 'btn-success' : styles.activeTypeBtn}`}>
                                                                        <input
                                                                            type="radio"
                                                                            name="paymentGateway"
                                                                            value="paypal"
                                                                            checked={paymentGateway === 'paypal'}
                                                                            onChange={() => handlePaymentSelection('paypal')}
                                                                            style={{ display: 'none' }} // Hide the default radio button
                                                                        />
                                                                        PayPal
                                                                    </label>
                                                                </div>
                                                                <div className='col-3 col-lg-4 mt-3'>
                                                                    <label className={`btn btn-border ${paymentGateway === 'stripe' ? 'btn-success' : styles.activeTypeBtn}`}>
                                                                        <input
                                                                            type="radio"
                                                                            name="paymentGateway"
                                                                            value="stripe"
                                                                            checked={paymentGateway === 'stripe'}
                                                                            onChange={() => handlePaymentSelection('stripe')}
                                                                            style={{ display: 'none' }} // Hide the default radio button
                                                                        />
                                                                        Stripe
                                                                    </label>
                                                                </div>

                                                                <div className='col-3 col-lg-4 mt-3 '>
                                                                    <label className={`btn btn-border  ${paymentGateway === 'razorpay' ? 'btn-success' : styles.activeTypeBtn}`}>
                                                                        <input
                                                                            type="radio"
                                                                            name="paymentGateway"
                                                                            value="razorpay"
                                                                            checked={paymentGateway === 'razorpay'}
                                                                            onChange={() => handlePaymentSelection('razorpay')}
                                                                            style={{ display: 'none' }} // Hide the default radio button
                                                                        />
                                                                        Razorpay
                                                                    </label>
                                                                </div>
                                                                {errors.paymentGateway && <div className="text-danger">{errors.paymentGateway}</div>}
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>



                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row justify-content-center'>
                                <div className={`col-4 `}>
                                    <button className="ch-btn-style-2 btn btn-success mt-4 w-100" style={{ padding: '1rem' }}
                                        onClick={handlePayment}
                                    >Pay Now</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    visible={isModalVisible}
                    onOk={handleOk}
                    className="customModal"
                    footer={false}
                    closable={false}
                >
                    <div
                        className="container-fluid d-flex justify-content-center align-items-center"
                        style={{ backgroundImage: 'url(/assets/images/banner/banner-alt.jpg)', filter: 'drop-shadow(2px 4px 6px black)' }}
                    >
                        <div className="row w-100 d-flex justify-content-center">
                            <div className="col-lg-12 model-cls" style={{ paddingTop: '10px' }}>
                                <button type="button" className="btn-close" onClick={handleCancel}></button>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12 d-flex justify-content-center">
                                <div className="card w-100">
                                    <div className="row no-gutters ">
                                        <div className="col-md-12">
                                            <div className="card-body p-4">
                                                <h5 className="text-center mb-4 title-font">Give the gift of trees!</h5>
                                                {/* <p>Register your account with given below the details</p> */}
                                                <form onSubmit={handleSubmit} >
                                                    <div className="mb-3">
                                                        <label htmlFor="email" className="form-label" style={{ color: 'white' }}>Reciever Name</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="recieverName"
                                                            name='recieverName'
                                                            value={formData.recieverName}
                                                            onChange={handleChange}
                                                        />
                                                        {errors.toEmail && <div className="text-danger">{errors.toEmail}</div>}
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="email" className="form-label" style={{ color: 'white' }}>Reciever Email</label>
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            id="fromEmail"
                                                            name='fromEmail'
                                                            value={formData.fromEmail}
                                                            onChange={handleChange}
                                                        />
                                                        {errors.fromEmail && <div className="text-danger">{errors.fromEmail}</div>}
                                                    </div>

                                                    <div className="mb-3">
                                                        <label htmlFor="username" className="form-label" style={{ color: 'white' }}>Occasion</label>
                                                        <select
                                                            className="form-control"
                                                            id="title"
                                                            name='title'  // Keeping the same name to maintain consistency with the formData
                                                            value={formData.title}
                                                            onChange={handleChange}
                                                        >
                                                            <option disabled value=''>Select an Occasion</option> {/* Default empty option */}
                                                            <option value="Birthday">Birthday</option>
                                                            <option value="Thanksgiving">Thanksgiving</option>
                                                            <option value="Valentine's Day">Valentine's Day</option>
                                                            <option value="Retirement">Retirement</option>
                                                            <option value="Mother's Day">Mother's Day</option>
                                                            <option value="Father's Day">Father's Day</option>
                                                            <option value="Newborn">Newborn</option>
                                                            <option value="Merry christmas">Merry christmas</option>
                                                            <option value="Anniversary">Anniversary</option>
                                                            <option value="Wedding">Wedding</option>
                                                            <option value="Graduation">Graduation</option>
                                                            <option value="Other">Other</option>
                                                        </select>
                                                        {errors.title && <div className="text-danger">{errors.title}</div>}
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="MobileNumber" className="form-label" style={{ color: 'white' }}>Mobile Number</label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            id="MobileNumber"
                                                            name='MobileNumber'
                                                            value={formData.MobileNumber}
                                                            onChange={handleChange}
                                                        />
                                                        {errors.title && <div className="text-danger">{errors.title}</div>}
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="message" className="form-label" style={{ color: 'white' }}>Message</label>
                                                        <TextArea
                                                            type="text"
                                                            className="form-control"
                                                            id="message"
                                                            name='message'
                                                            value={formData.message}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    {errors.message && <div className="text-danger">{errors.message}</div>}

                                                    <div className="mb-3">
                                                        <label htmlFor="deliveryDate" className="form-label" style={{ color: 'white' }}>Delivery Date</label>
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            id="delieveryDate"
                                                            name='delieveryDate'
                                                            value={formData.delieveryDate}
                                                            onChange={handleChange}
                                                        />
                                                        {errors.delieveryDate && <div className="text-danger">{errors.delieveryDate}</div>}
                                                    </div>
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary w-100 loginBtnStyle"
                                                    >
                                                        PLANT TREES
                                                    </button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        </>
    )
}

export default ViewCartPage